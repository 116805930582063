.custom-main-button {
    background-color: #1eb7ff;
    padding: 10px;
    border: none;
    border-radius: 10px;
    color: white;
    min-width: 130px;
    height: 50px;
}

.custom-a-1 {
    color: #1eb7ff;
}

.custom-login {
    border: 0px solid red;
    padding-top: 50px;
}

.custom-inside-log-in {
    border: 1px solid #1eb7ff;
    text-align: center;
    padding: 20px;
    border-radius: 20px;
}

.custom-form {
    text-align: left;
}

.custom-inside-log-in h2 {
    color: #1eb7ff;
}

.custom-inside-log-in p {
    color: #8493a5;
}

.custom-inside-log-in .form-label,
.form-check-label,
.form-text {
    color: #8493a5;
}