@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

:root {
  --primarycolor: #1d87ce;
  --whitecolor: #ffffff;
  --browncolor: #1f242f;
  --lettercolor: #5f646e;
  --blackcolor:black;
}

body {
  font-family: "Poppins";
}

::placeholder {
  color: #61697a !important;
  opacity: 1;
  /* Firefox */
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;

}


/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f100;
}

::-webkit-scrollbar-track {
  width: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 5px;
}

.input-form:focus{
    box-shadow: none;
    outline: none;
    border-color: #dee2e6 !important;
}

.variable{
  color: red !important;
}

.custom-right-nav-name-2 img {
  width: 30%;
  margin-right: 10px;
  margin-bottom: 10px;
}
